/***********************************************/
/* CajonStudio main javascript based on jQuery */
/***********************************************/
var over_popup = false;
(function($){
	///////////////////////////////////////////
	// on $(document).ready({...})
	///////////////////////////////////////////
	$(function() {
		var $container = jQuery('#gallery_ul');

	      $container.isotope({
	        itemSelector : '.gallery_li'
	      });
		// lightbox popup on images
		$(document).on('click', '*[data-toggle="lightbox"]', function(event) {
			event.preventDefault();
			$(this).ekkoLightbox();
		});
		// init carousel
		$('.carousel').carousel({
			interval: 5000
		});
		// set the highest size to all carousel items function
		var setCarouselHeight = function(id) {
			var slideHeight = [];
			$(id+' .item').each(function() {
				// add all slide heights to an array
				slideHeight.push($(this).height());
			});
			// find the tallest item
			max = Math.max.apply(null, slideHeight);
			// set the slide's height
			$(id+' .carousel-content').each(function() {
				$(this).css('height',max+'px');
			});
		};
		// set the highest size to all carousel items
		setCarouselHeight('#text_carousel');
		// set the first tab active
		$("#product_tab a:first").tab('show');
		// spin images array
		var cajonspin_images = $(".cajon_spin_item");
		// get spin
		var createSpriteSpin = function() {
			var spritespin_obj;
			var spritespin_obj2;
			var folder = $("#cajon_spin").data('cajonfoldername');
			var folder2 = $("#cajon_spin").data('cajonfoldername2');
			var ratio = $("#cajon_spin").data('cajonratio');
			var cajonspin_width = ($(".spinning_cajon").first().width() / 2);
			var source_images = [];
			for (var i = 0; i < cajonspin_images.length; i++) {
				source_images.push(folder + $(cajonspin_images[i]).data('cajonimageurl'));
			}
			if ( $( "#cajon_spin" ).length ) {
				spritespin_obj = $("#cajon_spin").spritespin({
					behavior: 'click',
					source : source_images,
					width : cajonspin_width,
					height : ratio * cajonspin_width,
					frameTime  : 400,
				});
			}
			if ( $( ".cajon_spin_large" ).length ) {
				spritespin_obj2 = $(".cajon_spin_large").spritespin({
					source : source_images,
					width   : 350,
					height  : ratio * 350,
					frameTime  : 400,
				});
			}
		};
		// create sprite spin 3D show
		createSpriteSpin();
		// Apply sticky header on scroll
		$(window).scroll(function() {
			if ($(window).scrollTop() > 100) {
				$('.header').addClass("sticky");
			} else {
				$('.header').removeClass("sticky");
			}
		});
		// Apply cart box on scroll
		var mq = window.matchMedia('@media all and (min-width: 992px)');
			if(mq.matches) {
				$(window).scroll(function() {
					if ($(window).scrollTop() > 200) {
						$('.add_product_to_cart_button').addClass("visible");
					} else {
						$('.add_product_to_cart_button').removeClass("visible");
					}
				});
			} else {
				$(window).scroll(function() {
					if ($(window).scrollTop() > 1000) {
						$('.add_product_to_cart_button').addClass("visible");
					} else {
						$('.add_product_to_cart_button').removeClass("visible");
					}
				});
			}
		
		// On window resize
		$(window).resize(function() {
			// set the slide's height to auto to extend
			$('#text_carousel .carousel-content').each(function() {
				$(this).css('height','auto');
			});
			// set the highest size to all carousel items
			setCarouselHeight('#text_carousel');
			// redo sprite spin 3D show
			createSpriteSpin();
		});

		$('.cookie_button').click(function(){
			$.ajax({
	            type: "POST",
	            url: "/accept-cookie-policy",
	            headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() }
	        }).done(function(data) {
	            if (data.success) {
	                $('#cookie_block').fadeOut();
	            }
	        });
		});

		$('.review_star').click(function(){
			//$('.review_star').removeClass('review_star_active');
			star = $(this).attr('data-star') * 1;
			$('input[name=rate]').val(star);
			$('.review_star').each(function(){
				current_star = $(this).attr('data-star') * 1;
				if (current_star <= star) {
					$(this).addClass('review_star_active');
				} else {
					$(this).removeClass('review_star_active');
				}
			});
		});

		$('#review_form .btn').click(function(){
			$.ajax({
	            type: "POST",
	            //url: "/accept-cookie-policy",
	            url: $('#review_form').attr('action'),
	            //url: 'velemeny-kuldes',
	            headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
	            data: $('#review_form').serialize()
	        }).done(function(data) {
	            if (data.success) {
	                $('.review_result').removeClass('hidden');
	                $('#review_form')[0].reset();
	                $('.review_star').removeClass('.review_star_active');
	            }
	        });
		});
	});
	///////////////////////////////////////////
	// Buttons
	///////////////////////////////////////////
	(function() {
		// Contact button animation
		$(".follow li").hover(function() {
				$(this).addClass('animated pulse');
			}, function() {
				$(this).removeClass('animated pulse');
		});
	})();

	/**************************************************************/
    /* Add item to shopping cart                                  */
    /**************************************************************/
    var addItemToShoppingCart = function() {
        var $this = $(this),
            id = $this.data('id');

        $.get("/api/addtocart/" + id, function(data, status) {
            if (data.addtocart != 0) {
                $('.cart_count')
                    .removeClass('hidden')
                    .html(data.count);
                $('.glyphicon').removeClass('hidden').html();
            }
            list_data = '';
            for (index = 0; index < data.cart_list_array.length; ++index) {
	            list_data += '<div id="item_'+data.cart_list_array[index]['rowid']+'" class="row">'+
				                '<div class="product_image col-xs-3">'+
			                        '<img src="/uploads/products/small/'+data.cart_list_array[index]['image']+'" class="img-responsive center-block" alt="'+data.cart_list_array[index]['title']+'" title="'+data.cart_list_array[index]['title']+'">'+
				                '</div>'+
				                '<div class="text-left col-xs-5 title">'+
				                    '<a href="'+data.cart_list_array[index]['link']+'">'+data.cart_list_array[index]['title']+'</a>'+
				                    '<div class="product_price">'+
				                        '<span class="rowprice product_price">'+data.cart_list_array[index]['price']+'</span>'+
				                    '</div>'+
				                '</div>'+
				                '<div class="margin_top center-block item_count col-xs-4">'+
				                    '<div class="button_spinner center-block" data-rowid="'+data.cart_list_array[index]['rowid']+'" >'+
				                        '<input type="text" class="touchspinner" id="qty_'+data.cart_list_array[index]['rowid']+'" value="'+data.cart_list_array[index]['qty']+'">'+
				                    '</div>'+
				                '</div>'+
				            '</div>';
			}
            $('.cart_popup_list').html(list_data);

            $('.cart_total').html(data.total);

            $(".cart_popup_list .touchspinner").TouchSpin({
		        min: 0,
		        max: 9,
		        step: 1
		    });
			
            $('body').addClass('cart_popup_active');
        });
    };
    /**************************************************************/
    /* Change quantity of item in shopping cart                   */
    /**************************************************************/
    var changeQuantityOfItemInShoppingCart = function() {
        var $this = $(this),
            rowId = $this.data("rowid"),
            qty = $this.find('.touchspinner').val();
        if (qty == 0) {
    		$.get("/api/removefromcart/" + rowId, function(data, status) {
	            if (data.removefromcart != 0) {
	                $('.cart_count').html(data.count);
	                $('.cart_total').html(data.total);
	            } else {
	                $('.cart_popup_list').html(data.cartlist);
	                $('.cart_count').addClass('hidden').html(data.count);
	                $('.cart_total').html(data.total);
	                $('.glyphicon').addClass('hidden').html();
	            }
	            $('#item_' + data.rowid).remove();
	            if ($('#cartlist table tbody tr').toArray().length == 0) {
	                $('#order_button_holder').addClass('hidden').html();
	            }

	        });
        } else {
	        $.get("/api/changeqtyincart/" + rowId + "/" + qty, function(data, status) {
	            if (data.addtocart != 0) {
	                $('.cart_count').removeClass('hidden').html(data.count);
	                $('.glyphicon').removeClass('hidden').html();
	                $('#item_' + data.rowid + ' .rowprice').html(data.rowprice);
	                $('.cart_total').html(data.total);

	                $('#item_' + data.rowid + ' .rowprice product_price').html(data.rowprice);
	            }
	        });
	    }
    };
    /**************************************************************/
    /* Remove item from shopping cart                               */
    /**************************************************************/
    var deleteItemFromShoppingCart = function() {
        var $this = $(this),
            rowId = $this.data('rowid');

        $.get("/api/removefromcart/" + rowId, function(data, status) {
            if (data.removefromcart != 0) {
                $('.cart_count').html(data.count);
                $('.cart_total').html(data.total);
            } else {
                $('#cartlist').html(data.cartlist);
                $('.cart_count').addClass('hidden').html(data.count);
                $('.glyphicon').addClass('hidden').html();
            }
            $('#item_' + data.rowid).remove();
            if ($('#cartlist table tbody tr').toArray().length == 0) {
                $('#order_button_holder').addClass('hidden').html();
            }

        });
    };
    /**************************************************************/
    /* Empty shopping cart                                        */
    /**************************************************************/
    var emptyShoppingCart = function() {
        $.get("/api/emptycart/", function(data, status) {
            $('#cartlist').html(data.cartlist);
            $('.cart_count').addClass('hidden').html();
            $('.glyphicon').addClass('hidden').html();
            $('#order_button_holder').addClass('hidden').html();
        });
    };
    /**************************************************************/
    /* Turn on/off invoice address block                          */
    /**************************************************************/
    var changeSameAddressOption = function() {
        if ($(this).is(":checked")) {
            $('#invoice').addClass('hidden');
        } else {
            $('#invoice').removeClass('hidden');
        }
    };
    /**************************************************************/
    /* All on event                                               */
    /**************************************************************/
    (function() {
        $('#content_box_holder')
            .on('click', '.add_to_cart', addItemToShoppingCart)
        $('#cartlist')
            .on('change', '.button_spinner', changeQuantityOfItemInShoppingCart)
            .on('click', '.button_delete_item_from_cart', deleteItemFromShoppingCart)
            .on('click', '.button_empty_cart', emptyShoppingCart);
        $('#order')
            .on('change', '#same_address', changeSameAddressOption)
        $('.cart_popup_wrap')
            .on('change', '.button_spinner', changeQuantityOfItemInShoppingCart)
    })();
    /**************************************************************/
    /* Intialize the number spinner plugin on webshop page        */
    /**************************************************************/
    $("#cartlist_body .touchspinner").TouchSpin({
        min: 1,
        max: 9,
        step: 1
    });
    $(".cart_popup_list .touchspinner").TouchSpin({
        min: 0,
        max: 9,
        step: 1
    });
	/**************************************************************/
    /* Paralax effect									          */
    /**************************************************************/
	$.hongkong();
    /**************************************************************/
    /* Bootsrap Validator                                         */
    /**************************************************************/
    if ($('#order').length) {
        $('#order_form').validator();
    }
	/**************************************************************/
	/* Intialize matchheight                                      */
	/**************************************************************/
	$('.matched_heights').matchHeight();
	/**************************************************************/
	/* Activates mmenu                                            */
	/**************************************************************/
	$("#main_menu_mmenu").mmenu({
		// options
		"slidingSubmenus": false,
		// configuration
		offCanvas: {
			pageSelector: "#container_for_mmenu"
		}
	});
	/**************************************************************/
	/* Activates map overlay                                           */
	/**************************************************************/
	$(".map_overlay").click(function(){
        $(this).hide();
    });
	 /**************************************************************/
	/* Activates Blazy - lazy loader                                            */
	/**************************************************************/
	window.bLazy = new Blazy({
		container: '.gallery_li_inner',
		success: function(element){
			console.log("Element loaded: ", element.nodeName);
			jQuery('#gallery_ul').isotope({
		        itemSelector : '.gallery_li'
		      });
		}
	});

	$('.close_cart_popup').click(function(){
		$('body').removeClass('cart_popup_active');
	});

	$('.cart_popup_wrap, a.basket').mouseenter(function(){
		over_popup = true;
	});

	$('.cart_popup_wrap, a.basket').mouseleave(function(){
		over_popup = false;
	});

	$('body').click(function(){
		if (!over_popup) {
			$('body').removeClass('cart_popup_active');
		}
	});

	$('a.basket').click(function(e){
		$('body').addClass('cart_popup_active');
		e.preventDefault();
	});
/*
	$('a[href="#velemenyek"]').click(function(e){
		e.preventDefault();
		$('html,body').animate({scrollTop: ($("#velemenyek").offset().top - $('body > .mm-page > header > .header').height())}, 'slow');
	});
*/
})(jQuery);